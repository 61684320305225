html {
  scroll-behavior: smooth;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --fdelay: 15s;
}

.bg-0 {
  background: url('assets/bg-0.webp');
  background-size: cover;
}

.bg-1 {
  background: url('assets/bg-1.png');
  background-size: cover;
}

.bg-2 {
  background: url('assets/bg-2.png');
  background-size: cover;
}

.pattern-1 {
  background: url('assets/pattern-1.png');
}
.pattern-2 {
  background: url('assets/pattern-2.png');
}
.pattern-3 {
  background: url('assets/pattern-3.png');
}

.fade_in {
  opacity: 0;
  transition: opacity 2s;
}
.fade_in--loaded {
  opacity: 1;
}
.float-x {
  animation: float-x var(--fdelay) infinite both;
}
.float-y {
  animation: float-y var(--fdelay) infinite both;
}

@keyframes float-y {
  0%   {transform: translateY(0)}
  25%  {transform: translateY(-5px)}
  75%  {transform: translateY(5px)}
  100% {transform: translateY(0)}
}

@keyframes float-x {
  0%   {transform: translateX(0)}
  25%  {transform: translateX(-5px)}
  75%  {transform: translateX(5px)}
  100% {transform: translateX(0)}
}
